<template>
  <div>
    <div class="titlebar with-switcher">
      <div class="medium-page-heading">
        Usage & Analytics
      </div>

      <div class="titlebar-switcher">
        <a href="" :class="{ 'selected': isTabSelected('member') }" @click.prevent="selectTab('member')">Member Usage</a>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div v-if="isTabSelected('member')">
            <usage-analytics-member-usage
              :client-usage-analytics="clientUsageAnalytics"
              :query-member-usage="queryMemberUsage">
            </usage-analytics-member-usage>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UsageAnalyticsMemberUsage from 'vue-app/scout/client/usage-analytics/usage-analytics-member-usage';

export default {
  name: 'UsageAnalyticsIndex',

  components: {
    UsageAnalyticsMemberUsage
  },

  props: {
    clientUsageAnalytics: {
      type:     Object,
      required: true
    },

    queryMemberUsage: {
      type:     Function,
      required: true
    }
  },

  data() {
    return {
      selectedTab: 'member'
    };
  },

  methods: {
    isTabSelected(tab) {
      return this.selectedTab === tab;
    },

    selectTab(tab) {
      if (this.selectedTab !== tab) {
        this.selectedTab = tab;
      }
    }
  }
};
</script>
