<template>
  <div>
    <div class="row md-columns vertical-center-not-xs">
      <div class="col-sm-5 col-md-4 col-lg-3">
        <form role="form" class="a-form" novalidate @submit.prevent>
          <typeahead-vertical
            v-model="searchQuery"
            :options="memberFullNames"
            id="member-usage"
            :force-select="false"
            :initial-value="searchQuery">
          </typeahead-vertical>
        </form>
      </div>

      <div class="col-sm-7 top-10-xs">
        <span class="semibold-weight">{{ membersCount }}</span> team {{ memberOrMembers }}

        <button type="button" class="pseudo-link-blue left-10" @click="clearSearch" v-if="renderClearSearch">(Clear Search)</button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-right-not-xs size-text-12px bottom-10">
        <a href="/scout/client_usage_analytics/member_usage.csv" target="_blank">Download CSV</a>
      </div>
    </div>

    <div class="resource-list">
      <div class="list-header">
        <div class="row tight-columns vertical-center-not-xs">
          <div class="col-sm-3 vertical-center">
            <dynamic-order-arrows
              class="vertical-center"
              :order="order"
              order-by="name"
              :initial-order="initialOrderMap['name']"
              :on-sort="sort"
              :reverse="true">
              <div>
                Name
              </div>
            </dynamic-order-arrows>
          </div>

          <div class="col-sm-9">
            <div class="row tight-columns vertical-center-not-xs">
              <div class="col-sm-1-5">
                <dynamic-order-arrows
                  class="vertical-center"
                  :order="order"
                  order-by="searchCount"
                  :initial-order="initialOrderMap['searchCount']"
                  :on-sort="sort"
                  :reverse="false">
                  <div>
                    Search Count
                  </div>
                </dynamic-order-arrows>
              </div>

              <div class="col-sm-1-5">
                <dynamic-order-arrows
                  class="vertical-center"
                  :order="order"
                  order-by="reviewsPublished"
                  :initial-order="initialOrderMap['reviewsPublished']"
                  :on-sort="sort"
                  :reverse="false">
                  <div>
                    Reviews Published
                  </div>
                </dynamic-order-arrows>
              </div>

              <div class="col-sm-1-5">
                <dynamic-order-arrows
                  class="vertical-center"
                  :order="order"
                  order-by="lastReviewPublishedAt"
                  :initial-order="initialOrderMap['lastReviewPublishedAt']"
                  :on-sort="sort"
                  :reverse="false">
                  <div>
                    Latest Review
                  </div>
                </dynamic-order-arrows>
              </div>

              <div class="col-sm-1-5">
                <dynamic-order-arrows
                  class="vertical-center"
                  :order="order"
                  order-by="listsCreated"
                  :initial-order="initialOrderMap['listsCreated']"
                  :on-sort="sort"
                  :reverse="false">
                  <div>
                    Lists Created
                  </div>
                </dynamic-order-arrows>
              </div>

              <div class="col-sm-1-5">
                <dynamic-order-arrows
                  class="vertical-center"
                  :order="order"
                  order-by="listsShared"
                  :initial-order="initialOrderMap['listsShared']"
                  :on-sort="sort"
                  :reverse="false">
                  <div>
                    Lists Shared
                  </div>
                </dynamic-order-arrows>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading-section name="memberUsage">
        <div v-for="member in paginatedMembers" class="list-item no-hover extra-padded" :key="`usage-analytics-member-${member.id}`">
          <div class="row tight-columns vertical-center-not-xs">
            <div class="col-sm-3 text-ellipsis">
              {{ member.fullName }}
            </div>

            <div class="col-sm-9">
              <div class="row tight-columns vertical-center-not-xs">
                <div class="col-sm-1-5">
                  {{ member.searchCount }}
                </div>

                <div class="col-sm-1-5">
                  {{ member.reviewsPublished }}
                </div>

                <div class="col-sm-1-5">
                  {{ lastReviewPublishedAt(member.lastReviewPublishedAt) || '&mdash;' }}
                </div>

                <div class="col-sm-1-5">
                  {{ member.listsCreated }}
                </div>

                <div class="col-sm-1-5">
                  {{ member.listsShared }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </loading-section>

      <div class="list-item empty single-item gray-text size-text-16px text-center" v-if="!hasMembers">
        No Members
      </div>
    </div>

    <priori-pagination
      class="scout-pagination top-15"
      :current-page="clientUsageAnalytics.currentPage"
      :total-entries="clientUsageAnalytics.totalMembersCount"
      :per-page="clientUsageAnalytics.perPage"
      @change="queryMemberUsageByPage">
    </priori-pagination>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter';
import PrioriPagination from 'vue-app/shared/components/priori-pagination.vue';
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import DynamicOrderArrows from 'vue-app/shared/components/dynamic-order-arrows.vue';
import { snakeCase } from 'lodash';

export default {
  name: 'UsageAnalyticsMemberUsage',

  components: {
    PrioriPagination,
    TypeaheadVertical,
    DynamicOrderArrows
  },

  props: {
    clientUsageAnalytics: {
      type:     Object,
      required: true
    },

    queryMemberUsage: {
      type:     Function,
      reqiured: true
    }
  },

  data() {
    return {
      currentPage: this.clientUsageAnalytics.currentPage,
      nameSortOrder: 'asc',
      searchQuery: '',
      renderClearSearch: false,
      initialOrderMap: {
        name: 'asc',
        searchCount: 'desc',
        reviewsPublished: 'desc',
        lastReviewPublishedAt: 'desc',
        listsCreated: 'desc',
        listsShared: 'desc'
      },
      order: { name: 'asc' },
      sortBy: 'name'
    };
  },

  computed: {
    paginatedMembers() {
      return this.clientUsageAnalytics.paginatedMembers;
    },

    members() {
      return this.clientUsageAnalytics.members;
    },

    hasMembers() {
      return this.members.length > 0;
    },

    memberOrMembers() {
      return `${this.$pluralize('member', this.membersCount)}`;
    },

    memberFullNames() {
      return this.clientUsageAnalytics.members.map(member => member.fullName);
    },

    membersCount() {
      return this.clientUsageAnalytics.totalMembersCount;
    }
  },

  watch: {
    searchQuery: function (memberFullName) {
      const member = this.members.find(member => member.fullName === memberFullName);

      if (member) {
        this.searchQuery = member.fullName;
        this.queryMemberUsage({ client_id: member.id });
        this.renderClearSearch = true;
      }
    }
  },

  methods: {
    lastReviewPublishedAt(date) {
      return DateFilter.filter(date);
    },

    sort(sortBy) {
      let sortOrder;

      if (this.order[sortBy]) {
        sortOrder = this.order[sortBy] === 'asc' ? 'desc' : 'asc';
      }
      else {
        sortOrder = this.initialOrderMap[sortBy];
      }

      this.sortBy = sortBy;
      this.order  = { [sortBy]: sortOrder };

      this.searchQuery   = '';

      this.queryMemberUsage({ current_page: this.currentPage, order: this.snakeCasedOrder() });
    },

    queryMemberUsageByPage(page) {
      this.currentPage = page;
      this.queryMemberUsage({ current_page: page, order: this.snakeCasedOrder() });
    },

    snakeCasedOrder() {
      return { [snakeCase(this.sortBy)]: this.order[this.sortBy] };
    },

    clearSearch() {
      this.currentPage = 1;
      this.nameSortOrder = 'asc';
      this.searchQuery = '';
      this.renderClearSearch = false;

      this.queryMemberUsage({ current_page: 1, order: { name: 'asc' } });
    }
  }
};
</script>
